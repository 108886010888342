import React from "react";

import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";

function Offer() {
  return (
    <>
      <div className="projects-5">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title" style={{ paddingTop: "100px" }}>
                OFERTA
              </h2>
              <h4 className="description">
                Wejdź do świata wyjątkowych smaków. <br />
                Świata Volante.
              </h4>
              <div className="section-space"></div>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="5">
              <Card
                className="card-background card-background-product productOffer"
                style={{
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "contain",
                  backgroundImage:
                    "url(" + require("assets/img/oliwka.png") + ")",
                }}
              >
                <CardBody></CardBody>
              </Card>
            </Col>
            <Col className="mr-auto" md="5">
              <div className="info info-horizontal">
                <h2 style={{ marginBottom: "0px", textAlign: "center" }}>
                  Oliwa Volante Premium Extra Virgin{" "}
                </h2>
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Oliwy najwyższej jakości charakteryzuje niski procent
                    wolnych kwasów tłuszczowych (mniej niż 0,8%). Oliwa z oliwek
                    Volante ma kwasowość na poziomie 0,12%. Jest to z pewnością
                    produkt klasy premium!
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Nieblendowana, niekupażowana. Volante używa oliwy z jednego
                    gatunku i jednej odmiany, charakteryzującej i
                    standaryzującej jej naturalny smak. Nie mieszamy oliwek z
                    różnych gajów ani nie mieszamy ich z innymi odmianami
                    oliwek.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Cały proces produkcji oliwy z oliwek odbywa się całkowicie
                    za pomocą środków mechanicznych, od zbioru po butelkowanie.
                    Zapewnia to bardziej wydajny i czystszy sposób wytwarzania
                    wysokiej jakości oliwy z oliwek.
                  </p>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <button className="button-84 lastBtn">
                  <a
                    class="offerLink"
                    href="http://sklep.byvolante.com/product/volante-premium/"
                  >
                    KUP
                  </a>
                </button>
              </div>
            </Col>
          </Row>
          <hr className="hrOffer" />
          <Row className="cacaoRow">
            <Col className="ml-auto mt-5" md="5">
              <div className="info info-horizontal offerHorizontal cacaoFirst">
                <h2
                  style={{
                    marginRight: "10px",
                    marginBottom: "5px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Kakao Volante Premium
                </h2>
                <div className="icon icon-warning arrowLeft">
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </div>
                <div className="icon icon-warning arrowRight">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Ciemne, o średnio gorzkim smaku zawiera wiele cennych
                    składników, m.in. witaminy B6, magnez, cynk, sód, potas czy
                    fosfor. Charakteryzuje je mocno czekoladowy smak.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal offerHorizontal">
                <div className="icon icon-warning arrowLeft">
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </div>
                <div className="icon icon-warning arrowRight">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Ziarna kakaowca, które rosną w tym regionie są najwyższej
                    jakości i nie mają żadnych obcych posmaków,
                  </p>
                </div>
              </div>
              <div className="info info-horizontal offerHorizontal">
                <div className="icon icon-warning arrowLeft">
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </div>
                <div className="icon icon-warning arrowRight">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Dzięki najwyższym standardom przy zbiorach nasze ziarna mają
                    czysty smak; nie ma w nim posmaku pleśni, specyficznego
                    aromatu wędzenia czy torfu.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal offerHorizontal">
                <div className="icon icon-warning arrowLeft">
                  <i className="now-ui-icons arrows-1_minimal-left"></i>
                </div>
                <div className="icon icon-warning arrowRight">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Duże ziarna kakaowca z dużą zawartością tłuszczu sprawiają,
                    że kakao ma bogaty, mocno czekoladowy smak, ma uniwersalne
                    zastosowanie w kuchni…i kosmetyce.{" "}
                  </p>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <button className="button-84 lastBtn">
                  <a
                    class="offerLink"
                    href="http://sklep.byvolante.com/product/kakao-volante/"
                  >
                    KUP
                  </a>
                </button>
              </div>
            </Col>
            <Col className="mr-auto cacaoOffer" md="5">
              <Card
                className="card-background card-background-product cacaoImage productOffer"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/kakao.png") + ")",
                }}
              >
                <CardBody>
                  <CardTitle tag="h2" style={{ fontSize: "26px" }}></CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <hr className="hrOffer" />
          <Row>
            <Col className="ml-auto" md="5">
              <Card
                className="card-background card-background-product productOffer"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/miod.png") + ")",
                }}
              >
                <CardBody>
                  <CardTitle tag="h2" style={{ fontSize: "26px" }}></CardTitle>
                  <p className="card-description"></p>
                </CardBody>
              </Card>
            </Col>
            <Col className="mr-auto offerLast" md="5">
              <div className="info info-horizontal">
                <h2
                  style={{
                    marginLeft: "10px",
                    marginBottom: "0px",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  Miód Volante Premium{" "}
                </h2>
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Miód powstaje w rodzinnej pasiece, produkowany jest w
                    tradycyjny sposób. Ma bursztynową barwę i cechuje się wyrazistym smakiem i aromatem.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Nie zawiera żadnych konserwantów, barwników ani sztucznych
                    aromatów.{" "}
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Miód chabrowy ma w sobie m.in. lizozym. Jest to enzym
                    ograniczający rozwój drobnoustrojów dzięki czemu działa
                    antybiotycznie.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <br />
                  <p className="description">
                    Zawiera w sobie również acetylocholinę i potas. Są to składniki
                    mające zbawienne działanie dla pracy serca.
                  </p>
                </div>
              </div>
              <div style={{ textAlign: "center" }}>
                <button className="button-84 lastBtn">
                  <a
                    class="offerLink"
                    href="http://sklep.byvolante.com/product/miod-chabrowy-volante/"
                  >
                    KUP
                  </a>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Offer;
