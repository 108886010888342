import React from "react";

import LykeNavbar from "./VolanteNavbar";

import {Container, Row, Col } from "reactstrap";

import logo from "../assets/img/logo3.png";

function LykeFooter() {
  return (
    <>
      <LykeNavbar />
      <hr className="footerHr" />
      <footer className="footer footer-white footer-big footerMobile">
        <Container>
          <div>
            <Row>
              <Col md="3">
                <img
                  className="footer-logo"
                  src={logo}
                  alt="czarne logo volante"
                ></img>
              </Col>
              <Col md="3">
                <h5 className="footerHeader">KONTAKT</h5>
                <ul className="links-vertical">
                  <li>
                    <span> 885 444 255</span>
                  </li>
                  <li>
                    <span>biuro@lyke.dk</span>
                  </li>
                  <li>
                    <span>Franklina Roosevelta 22,</span>
                  </li>
                  <li>
                    <span>60-829 Poznań</span>
                  </li>
                </ul>
              </Col>
              <Col md="3">
                <h5 className="footerHeader">DANE</h5>
                <ul className="links-vertical">
                  <li>
                    <span> LYKE SP. Z O.O.</span>
                  </li>
                  <li>
                    <span>NIP 7822649658</span>
                  </li>
                  <li>
                    <span>REGON 365559747</span>
                  </li>
                  <li>
                    <span>KRS 0000641727</span>
                  </li>
                </ul>
              </Col>
              <Col md="3">
                <h5 className="footerHeader footerDocs">DOKUMENTY</h5>
                <ul className="links-vertical">
                  <li>
                    <a
                      className="footerLink"
                      href="https://www.byvolante.com/regulamin.pdf"
                    >
                      Regulamin sklepu
                    </a>
                  </li>
                  <li>
                    <a
                      className="footerLink"
                      href="https://www.byvolante.com/polityka.pdf"
                    >
                      Polityka prywatności
                    </a>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
          <hr></hr>
        </Container>
      </footer>
    </>
  );
}

export default LykeFooter;
