// reactstrap components
import AboutUs from "./AboutUs";

// core components

function Header() {
  return (
    <>
      <div class="wrapperVideo">
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.4)",
          }}
        ></div>
        <video
          autoPlay
          loop
          muted
          class="wrapper__video"
          src="/video/head.mp4"
        />
      </div>

      <AboutUs />
    </>
  );
}

export default Header;
