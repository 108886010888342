import React from "react";
// react plugin used to create DropdownMenu for selecting items

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function AboutUs() {
  return (
    <>
      <div className="features-3">
        <Container>
          <Row>
            <Col className="mr-auto ml-auto" md="8">
              <h2 className="title">Volante. Wszystko z natury.</h2>
              <h4 className="description" style={{ marginBottom: "20px" }}>
                Marka Volante zrodziła się z dążenia do odnajdywania
                doskonałości. <br /> Z niezwykłą dbałością poszukujemy
                najwyższej jakości produktów by zaspokoić wymagania nasze i
                naszych klientów.
              </h4>
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-gold icon-circle">
                  <i className="now-ui-icons business_bulb-63"></i>
                </div>
                <h4 className="info-title">Wyselekcjonowane</h4>
                <p className="description">
                  Dokładamy wszelkich starań by oferowane przez nas produkty
                  były wyjątkowe.
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-gold icon-circle">
                  <i className="now-ui-icons location_bookmark"></i>
                </div>
                <h4 className="info-title">Najwyższej jakości</h4>
                <p className="description">
                  Współpracujemy tylko ze sprawdzonymi kontrahentami,
                  dostarczającymi produkty klasy premium.
                </p>
              </div>
            </Col>
            <Col md="4">
              <div className="info info-hover">
                <div className="icon icon-gold icon-circle">
                  <i className="now-ui-icons sport_trophy"></i>
                </div>
                <h4 className="info-title">Doskonałe</h4>
                <p className="description">
                  Współpracujemy tylko ze sprawdzonymi kontrahentami,
                  dostarczającymi produkty klasy premium.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="projects-5">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title">Wyjątkowa oferta Volante</h2>
              <h4 className="description">
                Nasza oferta nieustannie się rozwija, cały czas poszukujemy dla
                Państwa produktów najwyższej jakości…i je znajdujemy!
              </h4>
              <div className="section-space"></div>
            </Col>
          </Row>
          <Row>
            <Col className="ml-auto" md="5">
              <img
                className="card-background card-background-product card-raised aboutUsProducts"
                src={require("assets/img/oferta.jpeg")}
                alt="produkty volante"
              ></img>
            </Col>
            <Col className="mr-auto" md="5">
              <div className="info info-horizontal">
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">OLIWA </h4>
                  <p className="description">
                    Oliwa charakteryzuje się subtelnym smakiem przełamanym nutą
                    owocowego aromatu. Kwasowość Oliwy Volante to zaledwie
                    0,12%.
                  </p>
                </div>
              </div>
              <div className="info info-horizontal">
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">KAKAO</h4>
                  <p className="description">
                    Naturalne Kakao z Ghany w Afryce Zachodniej, wyprodukowane w
                    Holandii. Ciemne, o średnio gorzkim smaku zawiera wiele
                    cennych składników, m.in. witaminy B6, magnez, cynk, sód,
                    potas czy fosfor.{" "}
                  </p>
                </div>
              </div>
              <div
                className="info info-horizontal"
                style={{ paddingBottom: "100px" }}
              >
                <div className="icon icon-gold">
                  <i className="now-ui-icons arrows-1_minimal-right"></i>
                </div>
                <div className="description">
                  <h4 className="info-title">MIÓD</h4>
                  <p className="description">
                    Wyjątkowy Miód Chabrowy Volante powstaje w wielkopolskiej,
                    rodzinnej pasiece. Produkowany w tradycyjny sposób, miód,
                    nie zawiera żadnych konserwantów, barwników ani sztucznych
                    aromatów. Swój wyjątkowy smak zawdzięcza zbieranemu przez
                    pszczoły nektarowi bławatkowemu.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default AboutUs;
