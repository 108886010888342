/*!

=========================================================
* Now UI Kit PRO React - v1.5.1
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-kit-pro-react
* Copyright 2022 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.5.0";
import "assets/demo/demo.css?v=1.5.0";
import "assets/demo/react-demo.css?v=1.5.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.5.0";
// pages
import ContactPage from "components/ContactPage";
import VolanteWorld from "components/VolanteWorld";
import Offer from "components/Offer";
import ScrollToTop from "components/ScrollToTop";
import Footer from "components/Footer";
import Header from "components/Header";
// others

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <>
  <BrowserRouter>
    <ScrollToTop>
    <Switch>
      <Route path="/swiat-volante" render={(props) => <VolanteWorld {...props} />} />
      <Route path="/kontakt" render={(props) => <ContactPage {...props} />} />
      <Route path="/oferta" render={(props) => <Offer {...props} />} />
      <Route path="/" render={(props) => <Header {...props} />} />
      <Redirect to="/" />
    </Switch>
    </ScrollToTop>
    <Footer />
  </BrowserRouter>
  </>
);
