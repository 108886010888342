import React from "react";

import { Container, Row, Col } from "reactstrap";

const VolanteWorld = () => {
  return (
    <>
      <div className="wrapper">
        <div
          className="page-header page-header-small"
          style={{
            backgroundImage: "url(" + require("assets/img/vorld.jpg") + ")",
            backgroundSize: "cover",
          }}
        >
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, .25)",
            }}
          ></div>
          <div className="page-header-image"></div>
          <div className="content-center">
            <Container></Container>
          </div>
        </div>
        <div className="section section-about-us">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{ paddingTop: "0px" }}>
                  Świat Volante{" "}
                </h2>
                <h5 className="description">
                  W Volante wiemy, że najcenniejszy jest czas. <br /> Spotkania.
                  Rozmowa.
                </h5>
              </Col>
            </Row>
            <div className="separator separator-info"></div>
            <div className="section-story-overview">
              <Row>
                <Col md="6">
                  <div
                    className="image-container image-left"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/volantemiod.jpg") + ")",
                    }}
                  >
                    <p
                      className="blockquote blockquote-info"
                      style={{
                        color: "black",
                        fontWeight: "500",
                        fontStyle: "italic",
                      }}
                    >
                      "Jedną z tajemnic szczęśliwego życia, są nieustanne
                      maleńkie uczty."<br></br>
                      <br></br>
                      <small
                        style={{
                          color: "black",
                          fontStyle: "normal",
                        }}
                      >
                        - Iris Murdoch
                      </small>
                    </p>
                  </div>
                  <div
                    className="image-container image-left-bottom"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/kakaoworld.jpg") + ")",
                    }}
                  ></div>
                </Col>
                <Col md="5">
                  <div
                    className="image-container image-right"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/volanteoliwa.jpg") + ")",
                    }}
                  ></div>
                  <h3>
                    W Volante dostarczamy produkty, które sprawiają, że każda
                    chwila jest wyjątkowa.
                  </h3>
                  <p>
                    Celebrowanie codzienności, tych małych przebłysków, które
                    nas uszczęśliwiają, jest na wyciągnięcie ręki. Trzeba tylko
                    na chwilę się zatrzymać i dostrzec jak niewiele potrzeba,
                    aby w pełni zadbać o siebie.
                  </p>
                  <p>
                    Przecież doskonale wiemy, że zasługujemy na najlepsze
                    każdego dnia.
                  </p>
                  <p>
                    Wierzymy, że dobre życie składa się z wielu drobnych
                    elementów; pięknego otoczenia, przyjaznych ludzi,
                    niezapomnianych smaków.
                  </p>
                  <p>
                    W świecie Volante dbamy by każdy dzień mógł być świętem.
                  </p>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default VolanteWorld;
